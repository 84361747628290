export enum Steps {
  start = "",
  general = "#general",
  floors = "#floors",
  outerWalls = "#outer-walls",
  attic = "#attic",
  basement = "#basement",
  doorsWindows = "#doors-windows",
  hotWaterVentilation = "#hot-water-ventilation",
  photovoltaics = "#photovoltaics",
  customerPreferences = "#customer-preferences",
  photoUpload = "#photo-upload",
  plansAndDocumentsUpload = "#plans-and-documents-upload",
  final = "#final",
}

export const fromHash = (value: string): Steps => {
  switch (value) {
    case "":
      return Steps.start;
    case "#general":
      return Steps.general;
    case "#floors":
      return Steps.floors;
    case "#outer-walls":
      return Steps.outerWalls;
    case "#attic":
      return Steps.attic;
    case "#basement":
      return Steps.basement;
    case "#doors-windows":
      return Steps.doorsWindows;
    case "#hot-water-ventilation":
      return Steps.hotWaterVentilation;
    case "#photovoltaics":
      return Steps.photovoltaics;
    case "#customer-preferences":
      return Steps.customerPreferences;
    case "#photo-upload":
      return Steps.photoUpload;
    case "#plans-and-documents-upload":
      return Steps.plansAndDocumentsUpload;
    case "#final":
      return Steps.final;
    default:
      throw new Error(`"${value}" is not a valid value for Steps.`);
  }
};

export enum WallMaterials {
  brick = "Ziegelmauerwerk",
  concrete = "Beton",
  wood = "Massivholzwand",
  doubleLeafWall = "zweischaliges Mauerwerk",
  hollowBrick = "Hochlochziegel",
  halfWoodBrick = "Fachwerk (Ziegel)",
  halfWoodClay = "Fachwerk (Lehm)",
}

export enum InsulationMaterials {
  mineralWool = "Mineralwolle",
  stoneWool = "Steinwolle",
  styrofoam = "Polystyrol/Styropor",
  woodFiber = "Holzfaser",
}

export enum FrameMaterial {
  wood = "Holz",
  metal = "Metall",
  plastic = "Kunststoff",
}

export enum WaterHeating {
  sameAsHeating = "über Heizung (zentral)",
  waterHeater = "Durchlauferhitzer (dezentral)",
  gasBoiler = "Gas-Boiler (dezentral)",
  miscellanous = "Sonstiges",
}

export enum Ventilation {
  windows = "Fensterlüftung",
  fan = "Lüftungsanlage",
  airConditioning = "Klimaanlage",
  other = "andere",
}

export enum PreferredHeating {
  heatPump = "Wärmepumpe",
  biomass = "Biomasse (Holz/Pellets)",
  solar = "Solarthermie",
  disrictHeating = "Fernwärme",
  dontKnow = "ich habe dazu Beratungsbedarf",
}

export enum RenovationPlans {
  exteriorWalls = "Dämmung Außenwände",
  roof = "Dämmung Dach / obere Geschossdecke",
  basement = "Dämmung Keller / Bodenplatte",
  heating = "Heizungstausch / Optimierung",
  windows = "Neue Fenster / Haustür",
  photovoltaic = "Photovoltaik",
  solar = "Solarthermie",
  fullRenovation = "Sanierung in einem Zug zum KFW Effizienzhaus",
  dontKnow = "ich habe dazu Beratungsbedarf",
}
