import { Grow, InputAdornment, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import FormScreen from "../FormScreen";

export default function PhotovoltaicsScreen({ prev, next }: ScreenProps) {
  const [pvInstalled, setPvInstalled] = useState("");
  const [fullfeed, setFullfeed] = useState("");
  const [batteryInstalled, setBatteryInstalled] = useState("");
  const [solarInstalled, setSolarInstalled] = useState("");

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Schritt 8: </strong>Informationen zu Erneuerbaren Energien
          </Typography>
        </Grid>
        <Grid size={12}>
          <Stack direction='row' alignItems='baseline' spacing={2}>
            <Typography flexGrow={1} maxWidth='50%'>
              Ist aktuell Photovoltaik vorhanden?
            </Typography>
            <ToggleButtonGroup size='small' exclusive value={pvInstalled} onChange={(event, value) => setPvInstalled(value)} sx={{ flexGrow: 1 }}>
              <ToggleButton value='yes'>Ja</ToggleButton>
              <ToggleButton value='no'>Nein</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        {pvInstalled === "yes" && (
          <>
            <Grid size={6}>
              <Grow in timeout={800}>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='pv-panels-capacity-input'
                  label='Photovoltaik Kapazität'
                  required={true}
                  type='number'
                  slotProps={{
                    htmlInput: {
                      min: 1,
                      max: 25,
                    },
                    input: {
                      endAdornment: <InputAdornment position='end'>kWp</InputAdornment>,
                    },
                  }}
                />
              </Grow>
            </Grid>
            <Grid size={6}>
              <Grow in timeout={800}>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='pv-panels-year-input'
                  label='Photovoltaik Installationsjahr'
                  required={true}
                  type='number'
                  slotProps={{
                    htmlInput: {
                      min: 2000,
                      max: new Date().getFullYear(),
                    },
                  }}
                />
              </Grow>
            </Grid>
            <Grid size={12}>
              <Grow in timeout={800}>
                <Stack direction='row' alignItems='baseline' spacing={2}>
                  <Typography flexGrow={1} maxWidth='50%'>
                    Betreiben Sie PV-Volleinspeisung in das Stromnetz?
                  </Typography>
                  <ToggleButtonGroup size='small' exclusive value={fullfeed} onChange={(event, value) => setFullfeed(value)} sx={{ flexGrow: 1 }}>
                    <ToggleButton value='yes'>Ja</ToggleButton>
                    <ToggleButton value='no'>Nein</ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </Grow>
            </Grid>
            <Grid size={12}>
              <Grow in timeout={800}>
                <Stack direction='row' alignItems='baseline' spacing={2}>
                  <Typography flexGrow={1} maxWidth='50%'>
                    Ist ein Batteriespeicher in Verbindung mit der PV-Anlage vorhanden?
                  </Typography>
                  <ToggleButtonGroup
                    size='small'
                    exclusive
                    value={batteryInstalled}
                    onChange={(event, value) => setBatteryInstalled(value)}
                    sx={{ flexGrow: 1 }}
                  >
                    <ToggleButton value='yes'>Ja</ToggleButton>
                    <ToggleButton value='no'>Nein</ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </Grow>
            </Grid>
          </>
        )}
        {pvInstalled === "yes" && batteryInstalled === "yes" && (
          <>
            <Grid size={6}>
              <Grow in timeout={800}>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='pv-battery-capacity-input'
                  label='Batterie Kapazität'
                  required={true}
                  type='number'
                  inputProps={{ min: 1, max: 25 }}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>kWh</InputAdornment>,
                  }}
                />
              </Grow>
            </Grid>
            <Grid size={6}>
              <Grow in timeout={800}>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='pv-battery-year-input'
                  label='Installationsjahr Batteriespeicher'
                  required={true}
                  type='number'
                  inputProps={{ min: 2000, max: new Date().getFullYear() }}
                />
              </Grow>
            </Grid>
          </>
        )}
        <Grid size={12}>
          <Stack direction='row' alignItems='baseline' spacing={2}>
            <Typography flexGrow={1} maxWidth='50%'>
              Ist Solarthermie vorhanden?
            </Typography>
            <ToggleButtonGroup size='small' exclusive value={solarInstalled} onChange={(event, value) => setSolarInstalled(value)} sx={{ flexGrow: 1 }}>
              <ToggleButton value='yes'>Ja</ToggleButton>
              <ToggleButton value='no'>Nein</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        {solarInstalled === "yes" && (
          <Grid size={6}>
            <Grow in timeout={800}>
              <TextField
                fullWidth
                variant='outlined'
                id='solar-capacity-input'
                label='Größe'
                required={true}
                type='number'
                slotProps={{
                  htmlInput: {
                    min: 1,
                    max: 25,
                  },
                  input: {
                    endAdornment: <InputAdornment position='end'>m²</InputAdornment>,
                  },
                }}
              />
            </Grow>
          </Grid>
        )}
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Erneuerbare Energien' progressValue={66} content={content} helpContent={helpContent} />;
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Sollte Ihr Haus bereits über
      <br />
      Erneuerbare Energien verfügen,
      <br />
      beziehen wir diese Kapazität in
      <br />
      unsere Sanierungsempfehlungen mit ein.
      <br />
      Falls Sie keine Erneuerbaren Energien
      <br />
      haben, prüfen wir, ob Ihr Gebäude
      <br />
      dafür geeignet ist und weisen auf
      <br />
      Förderprogramme auf Bundesebene hin.
    </Typography>
  );
}
