import { Stack, Tooltip, Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import FormScreen from "../FormScreen";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import NovoImageUpload from "../../../../components/common/NovoImageUpload";

export default function PhotoUploadScreen({ prev, next }: ScreenProps) {
  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Schritt 10: </strong>Upload von Gebäudefotos (max. 4 Bilder)
          </Typography>
        </Grid>
        <Grid size={12}>
          <Stack direction='row' spacing={1} sx={{ alignItems: "top" }}>
            <NovoImageUpload multiple label='Fotos Hochladen' direction='column' maxImages={4} required />
            <Tooltip title='Laden Sie hier bitte 4 Foto der Außenfassaden Ihres Gebäudes hoch, ein Fotos von jeder Seite.'>
              <HelpOutlineOutlinedIcon sx={{ fontSize: "1rem", ml: 1, alignSelf: "top" }} />
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Gebäudefotos' progressValue={83} content={content} helpContent={helpContent} />;
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Die Gebäudefotos werden benötigt,
      <br />
      um einen ersten Eindruck Ihrer
      <br />
      Immobilien zu erlangen.
      <br /> <br />
      Sie ersetzen nicht den
      <br />
      Vor Ort-Besuch, helfen uns
      <br />
      jedoch, eine Ersteinschätzung
      <br />
      Ihres Gebäudes vorzunehmen.
    </Typography>
  );
}
