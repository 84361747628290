import CloseIcon from "@mui/icons-material/Close";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, IconButton, Stack, Typography } from "@mui/material";

import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";
import NovoFileUpload from "../../../../components/common/NovoFileUpload";
import { useFetchLead } from "../../../../hooks/useFetchLead";
import { useFinalizeIsfpProcess } from "../../../../hooks/useFinalizeIsfpProcess";
import { IsfpProcessStatus } from "../../../../types/cockpit/types";
import { DisplayBubbleFlowRenovationDialog } from "../../leads/leadlist/DisplayBubbleFlowRenovationDialog";
import { RowDetailProps } from "./types";

export default function IsfpRenovationAnswered({ row, onChange }: RowDetailProps) {
  const { getLead, isLoading: isLoadingLead, lead } = useFetchLead();
  const [isBubbleSchnellCheckOpen, setBubbleSchnellCheckOpen] = useState(false);
  const [isFinalizeModalOpen, setFinalizeModalOpen] = useState(false);

  const toggleFinalizeModal = () => {
    setFinalizeModalOpen(!isFinalizeModalOpen);
  };

  const toggleBubbleSchnellCheckModal = () => {
    setBubbleSchnellCheckOpen(!isBubbleSchnellCheckOpen);
  };

  const openBubbleQuestionnaireDetails = async (leadId?: string) => {
    if (!leadId) {
      return;
    }
    getLead(leadId);
  };

  useEffect(() => {
    setBubbleSchnellCheckOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead]);

  function openISFPFinalizeModal() {
    setFinalizeModalOpen(true);
  }

  return (
    <>
      <Box>
        <Typography>
          iSFP-Fragebogen wurde ausgefüllt.
          {IsfpProcessStatus.INVITE_ACCEPTED && !row.schnellCheckId && (
            <Box display={"inline"} pl={2}>
              <ButtonWithSpinner
                variant='outlined'
                color='secondary'
                loading={isLoadingLead}
                label='Antworten ansehen'
                onClick={() => openBubbleQuestionnaireDetails(row.leadId)}
              />
            </Box>
          )}
        </Typography>
        {row.status == IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_ANSWERED && (
          <Typography pt={2}>
            iSFP Dokument in Bearbeitung.
            <Box display={"inline"} pl={2}>
              <ButtonWithSpinner
                data-cy={`set-offer-accepted-bnt-${row.leadEmail}`}
                variant={"contained"}
                label='iSFP Prozess abschließen'
                onClick={openISFPFinalizeModal}
              />
            </Box>
          </Typography>
        )}
      </Box>
      <DisplayBubbleFlowRenovationDialog
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        bubbleFlow={lead?.properties?.find((property) => property.isfp?.bubbleFlow)?.isfp?.bubbleFlow!}
        open={isBubbleSchnellCheckOpen}
        handleClose={toggleBubbleSchnellCheckModal}
      />
      <FinalizeIsfpProcessDialog open={isFinalizeModalOpen} onClose={toggleFinalizeModal} row={row} onChange={onChange} />
    </>
  );
}
type FinalizeIsfpProcessDialogProps = {
  open: boolean;
  onClose: () => void;
} & RowDetailProps;

const FinalizeIsfpProcessDialog = ({ row, open, onClose, onChange }: FinalizeIsfpProcessDialogProps) => {
  const { finalizeIsfpProcess, resetState, updatedIsfpProcess, isLoading, finalizeError } = useFinalizeIsfpProcess();
  const [file, setFile] = useState<File>();
  const [fileError, setFileError] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (updatedIsfpProcess && onChange) {
      onGoingToClose();
      onChange(updatedIsfpProcess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedIsfpProcess]);

  useEffect(() => {
    if (finalizeError) {
      setError("Es ist leider ein Fehler aufgetreten");
    } else {
      setError(undefined);
    }
  }, [finalizeError]);

  function handleFinalize(): void {
    if (!file) {
      setFileError(true);
      return;
    }
    finalizeIsfpProcess(file, row.id);
  }

  function handleFileChange(value: File[]): void {
    if (value.length == 1) {
      setFileError(false);
      setFile(value[0]);
    }
  }

  function onGoingToClose(): void {
    setFile(undefined);
    resetState();
    onClose();
  }
  return (
    <Dialog open={open} onClose={onGoingToClose} maxWidth={"xl"}>
      <Box data-cy='finilize-isfp-modal' sx={{ p: 2 }}>
        <DialogTitle>
          iSFP Prozess abschließen
          <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={onGoingToClose} data-cy='invite-lead-cancel-btn'>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack display={"inline"}>
            <Typography pb={2}>Beim Hochladen der iSPF-Datei wird der Prozess abgeschlossen</Typography>
            <Grid2 minWidth={800}>
              <NovoFileUpload maxFiles={1} error={fileError} required label='iSFP Dokument' direction='row' onChange={handleFileChange} />
              {fileError && (
                <Typography color='error.main' variant='caption'>
                  Bitte wähle eine iSFP-Datei aus
                </Typography>
              )}
            </Grid2>
            {error && (
              <Alert data-cy='finalize-error' sx={{ marginTop: 2 }} severity='error'>
                {error}
              </Alert>
            )}
          </Stack>
        </DialogContent>
      </Box>

      <DialogActions sx={{ pb: 5, pr: 5 }}>
        <Button variant='outlined' color='secondary' onClick={onGoingToClose} disabled={isLoading}>
          Abbrechen
        </Button>
        <Box display={"inline"} pl={2}>
          <ButtonWithSpinner data-cy='isfp-finish-process-btn' variant={"contained"} loading={isLoading} label='Abschließen' onClick={handleFinalize} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
