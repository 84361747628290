import { Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import FormScreen from "../FormScreen";
import InsulationComponent from "./InsulationComponent";

export default function AtticScreen({ prev, next }: ScreenProps) {
  const [attic, setAttic] = useState("");

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Schritt 4: </strong>Informationen zum Dach
          </Typography>
        </Grid>
        <Grid size={12}>
          <Stack direction='row' alignItems='baseline' spacing={2}>
            <Typography flexGrow={1} maxWidth='50%'>
              Hat das Haus einen Dachboden? Ist dieser beheizt?
            </Typography>
            <ToggleButtonGroup
              size='small'
              exclusive
              value={attic}
              onChange={(_, value) => {
                setAttic(value);
              }}
              sx={{ flexGrow: 1 }}
            >
              <ToggleButton value='heated'>Ja, beheizt</ToggleButton>
              <ToggleButton value='partially-heated'>Ja, teilweise beheizt</ToggleButton>
              <ToggleButton value='not-heated'>Ja unbeheizt</ToggleButton>
              <ToggleButton value='no'>Nein</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        {(attic === "heated" || attic === "no") && (
          <Grid size={12}>
            <InsulationComponent fadein question='Ist das Dach gedämmt?' picture='House_sections_roof_insulation.png' />
          </Grid>
        )}
        {attic === "not-heated" && (
          <Grid size={12}>
            <InsulationComponent fadein question='Is the attic floor insulated?' picture='House_sections_ceiling_insulation.png' />
          </Grid>
        )}
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Dach' progressValue={33} content={content} helpContent={helpContent} />;
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Über das Dach geht die meiste Wärme
      <br />
      verloren. Daher ist die Dämmung
      <br />
      des Daches bzw. der oberen
      <br />
      Geschossdeckeeine der wirksamsten
      <br />
      energetischen Maßnahmen.
      <br />
      <br />
      Ihre Angaben helfen uns,
      <br />
      die aktuelle Energieeffizienz
      <br />
      Ihres Hauses so präzise wie
      <br />
      möglich zu berechnen.
    </Typography>
  );
}
