import { Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { FrameMaterial } from "./utils";
import { useState } from "react";
import NovoSelect from "../../../../components/common/NovoSelect";
import FormScreen from "../FormScreen";

export default function DoorsAndWindowsScreen({ prev, next }: ScreenProps) {
  const [multiWindowTypes, setMultiWindowTypes] = useState("");

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Schritt 6: </strong>Informationen zu Fenstern und Haustür
          </Typography>
        </Grid>
        <Grid size={6}>
          <NovoSelect label={"Material der Fensterrahmen"} options={[FrameMaterial.wood, FrameMaterial.metal, FrameMaterial.plastic]} value={""} required />
        </Grid>
        <Grid size={6}>
          <Stack direction='row' alignItems='baseline' spacing={2}>
            <Typography>Gibt es unterschiedliche Fenstertypen?</Typography>
            <ToggleButtonGroup size='small' exclusive value={multiWindowTypes} onChange={(event, value) => setMultiWindowTypes(value)}>
              <ToggleButton value='yes'>Ja</ToggleButton>
              <ToggleButton value='no'>Nein</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        <Grid size={6}>
          <TextField
            fullWidth
            variant='outlined'
            id='windows-installation-year-input'
            label='Baujahr Fenster'
            required={true}
            type='number'
            inputProps={{ min: 1900, max: new Date().getFullYear() }}
          />
        </Grid>
        <Grid size={6}>
          <NovoSelect label={"Material der Haustür"} options={[FrameMaterial.wood, FrameMaterial.metal, FrameMaterial.plastic]} value={""} required />
        </Grid>
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Fenster & Haustür' progressValue={50} content={content} helpContent={helpContent} />;
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Über die letzten Jahrzehnte hat sich
      <br />
      die Energieeffizienz von Fenstern
      <br />
      stark erhöht.
      <br />
      <br />
      Durch die Angabe des Baujahres
      <br />
      der Fenster können wir den Ist-Zustand
      <br />
      der Fenster besser beurteilen und
      <br />
      die potentielle Energieeinsparung
      <br />
      durch neue Fenster berechnen.
    </Typography>
  );
}
