import { Stack, Tooltip, Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { PreferredHeating, RenovationPlans } from "./utils";
import NovoSelect from "../../../../components/common/NovoSelect";
import NovoMultiSelect from "../../../../components/common/NovoMultiSelect";
import FormScreen from "../FormScreen";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

export default function CustomerPreferencesScreen({ prev, next }: ScreenProps) {
  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Schritt 9: </strong>Informationen zu Sanierungswünschen
          </Typography>
        </Grid>
        <Grid size={12}>
          <Stack>
            <Stack direction='row'>
              <Typography>Was ist Ihr zukünftig bevorzugtes Heizsystem?</Typography>
              <Tooltip title='Wir prüfen unabhängig von Ihrer Angabe, ob Ihre Heizung überhaupt getauscht werden muss.'>
                <HelpOutlineOutlinedIcon sx={{ fontSize: "1rem", ml: 1, alignSelf: "top" }} />
              </Tooltip>
            </Stack>
            <NovoSelect
              label={"bevorzugtes Heizsystem"}
              options={[
                PreferredHeating.heatPump,
                PreferredHeating.biomass,
                PreferredHeating.solar,
                PreferredHeating.disrictHeating,
                PreferredHeating.dontKnow,
              ]}
              value={""}
              required
              sx={{ mt: 1 }}
            />
          </Stack>
        </Grid>
        <Grid size={12}>
          <Stack>
            <Stack>
              <Typography>Welche Maßnahmen möchten Sie umsetzen?</Typography>
              <Typography fontSize={11} fontWeight={400}>
                Mehrfachauswahl möglich
              </Typography>
            </Stack>
            <NovoMultiSelect
              label={"gewünschte Maßnahmen"}
              options={[
                RenovationPlans.exteriorWalls,
                RenovationPlans.roof,
                RenovationPlans.basement,
                RenovationPlans.heating,
                RenovationPlans.windows,
                RenovationPlans.photovoltaic,
                RenovationPlans.solar,
                RenovationPlans.fullRenovation,
                RenovationPlans.dontKnow,
              ]}
              value={[]}
              sx={{ mt: 1 }}
            />
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Sanierungswünsche' progressValue={75} content={content} helpContent={helpContent} />;
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Wir wollen Ihnen einen
      <br />
      Sanierungsfahrplanerstellen, der zu
      <br />
      Ihnen passt!
      <br /> <br />
      Dafür ist es wichtig,
      <br />
      dass wir wissen, was Ihnen
      <br />
      wichtig ist und welche
      <br />
      Maßnahmen Sie sich vorstellen
      <br />
      können.
    </Typography>
  );
}
