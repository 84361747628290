import { Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import NovoFileUpload from "../../../../components/common/NovoFileUpload";
import FormScreen from "../FormScreen";

export default function PlansAndDocumentsUploadScreen({ prev, next }: ScreenProps) {
  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Schritt 11: </strong>Gebäudepläne
          </Typography>
        </Grid>
        <Grid size={12}>
          <NovoFileUpload multiple label='Grundrisse' accept='image/*,.pdf' tooltip='Max. 4 Dateien' required />
        </Grid>
        <Grid size={12}>
          <NovoFileUpload
            multiple
            label='Schnitte'
            accept='image/*,.pdf'
            tooltip='Damit sind Zeichnungen der Seitenansichten Ihres Hauses gemeint. Max. 4 Dateien'
            required
          />
        </Grid>
        <Grid size={12}>
          <NovoFileUpload multiple label='Sonstige' accept='*/*' tooltip='Baubeschreibung / Gebäudegutachten / Sonstige Unterlagen. Max. 4 Dateien' />
        </Grid>
        <Grid size={12}>
          <NovoFileUpload multiple label='Energieausweis' accept='image/*,.pdf' maxFiles={1} />
        </Grid>
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Pläne' progressValue={91} content={content} helpContent={helpContent} />;
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Die Gebäudepläne werden für das
      <br />
      3D-Modell Ihres Gebäudes benötigt.
      <br />
      Anhand dieses Modells berechnen wir
      <br />
      energetische Verbesserungen und
      <br />
      erarbeiten die einzelnen
      <br />
      Sanierungsschritte in Ihrem
      <br />
      Sanierungsfahrplan.
    </Typography>
  );
}
