import { Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { WallMaterials } from "./utils";
import NovoSelect from "../../../../components/common/NovoSelect";
import FormScreen from "../FormScreen";
import InsulationComponent from "./InsulationComponent";

export default function OuterWallsScreen({ prev, next }: ScreenProps) {
  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Schritt 3: </strong>Informationen zu den Außenwänden
          </Typography>
        </Grid>
        <Grid size={6}>
          <NovoSelect
            label={"Aufbau Außenwand"}
            options={[
              WallMaterials.brick,
              WallMaterials.concrete,
              WallMaterials.doubleLeafWall,
              WallMaterials.hollowBrick,
              WallMaterials.wood,
              WallMaterials.halfWoodBrick,
              WallMaterials.halfWoodClay,
            ]}
            value={""}
            required
          />
        </Grid>
        <Grid size={12}>
          <InsulationComponent question='Sind die rot markierten Wände gedämmt?' picture='House_sections_outer_walls.png' />
        </Grid>
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Außenwände' progressValue={25} content={content} helpContent={helpContent} />;
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Die Dicke und das Material einer
      <br />
      möglichen Dämmung wirken sich auf
      <br />
      die individuelle Energiebilanz aus.
      <br />
      <br />
      Um den Ist-Zustand Ihrer Immobilie
      <br />
      bestmöglich zu berechnen,
      <br />
      benötigen wir diese Informationen.
    </Typography>
  );
}
