import Grid from "@mui/material/Grid2";
import { ScreenProps } from "./RenovationsFunnel";
import { Checkbox, FormControl, Paper, Stack, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import useImage from "../../../../hooks/useImage";
import FormScreen from "../FormScreen";
import { useState } from "react";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

export default function GeneralInfoScreen({ prev, next }: ScreenProps) {
  const [expansionPlans, setExpansionPlans] = useState<string>("");
  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Schritt 1: </strong>Informationen zum Haus
          </Typography>
        </Grid>
        <Grid size={12}>
          <TypeSelector />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Stack direction='row'>
            <TextField
              fullWidth
              variant='outlined'
              id='house-units-input'
              label='Anzahl der Wohneinheiten'
              required={true}
              type='number'
              inputProps={{ min: 1, max: 20 }}
            />
            <Tooltip title='Eine Wohneinheit hat einen eigenen abschließbaren Eingang,  ein eigenes Bad und eine Küche.'>
              <HelpOutlineOutlinedIcon sx={{ fontSize: "1rem", ml: 1, alignSelf: "top" }} />
            </Tooltip>
          </Stack>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Stack direction='row' alignItems='baseline' spacing={2}>
            <Typography>Ist im Zuge der Sanierung ein Anbau/Ausbau geplant?</Typography>
            <ToggleButtonGroup size='small' exclusive value={expansionPlans} onChange={(event, value) => setExpansionPlans(value)}>
              <ToggleButton value='yes'>Anbau geplant</ToggleButton>
              <ToggleButton value='no'>kein Anbau geplant</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Hausinformationen' progressValue={8} content={content} helpContent={helpContent} />;
}

function TypeSelector() {
  const detached = useImage("Detached.png");
  const semi = useImage("Semi.png");
  const terraced = useImage("Terraced.png");
  return (
    <FormControl fullWidth>
      <Grid
        container
        flexGrow={1}
        spacing={{
          xs: 2,
          lg: 4,
        }}
      >
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack component={Paper} direction='row' alignItems='center' sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", height: "100%" }}>
            <Stack alignItems={"start"} spacing={1} flexGrow={1}>
              <img src={detached.image} height={45} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                freistehend
              </Typography>
            </Stack>
            <Checkbox sx={{ "&.Mui-checked": { color: "text.primary" } }} />
          </Stack>
        </Grid>
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack component={Paper} direction='row' alignItems='center' sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", height: "100%" }}>
            <Stack alignItems={"start"} spacing={1} flexGrow={1}>
              <img src={semi.image} height={40} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Reihenendhaus / Doppelhaushälfte
              </Typography>
            </Stack>
            <Checkbox sx={{ "&.Mui-checked": { color: "text.primary" } }} />
          </Stack>
        </Grid>
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack component={Paper} direction={"row"} alignItems='center' sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", height: "100%" }}>
            <Stack alignItems={"start"} spacing={1} flexGrow={1}>
              <img src={terraced.image} height={40} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Reihenmittelhaus
              </Typography>
            </Stack>
            <Checkbox sx={{ "&.Mui-checked": { color: "text.primary" } }} />
          </Stack>
        </Grid>
      </Grid>
    </FormControl>
  );
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Der Haustyp hat einen
      <br />
      entscheidenden Einfluss auf
      <br />
      Wärmeverluste und sinnvolle
      <br />
      Empfehlungen zur Modernisierung.
      <br />
      <br />
      Und hier noch eine gute Nachricht:
      <br />
      Auch bestimmte Anbauten können
      <br />
      unter Umständen Förderung erhalten.
    </Typography>
  );
}
