import { Divider, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import FormScreen from "../FormScreen";
import InsulationComponent from "./InsulationComponent";

export default function BasementScreen({ prev, next }: ScreenProps) {
  const [basement, setBasement] = useState("");

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Schritt 5: </strong>Informationen zum Keller oder Boden
          </Typography>
        </Grid>
        <Grid size={12}>
          <Stack direction='row' alignItems='baseline' spacing={2}>
            <Typography>Hat das Haus einen Keller? Ist dieser beheizt?</Typography>
            <ToggleButtonGroup
              size='small'
              exclusive
              value={basement}
              onChange={(_, value) => {
                setBasement(value);
              }}
            >
              <ToggleButton value='heated'>Ja, beheizt</ToggleButton>
              <ToggleButton value='partially-heated'>Ja, teilweise beheizt</ToggleButton>
              <ToggleButton value='not-heated'>Ja unbeheizt</ToggleButton>
              <ToggleButton value='no'>Nein</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        {(basement === "heated" || basement === "partially-heated" || basement === "no") && (
          <>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={12}>
              <InsulationComponent fadein question='Ist die Bodenplatte gedämmt?' picture='House_sections_floor_insulation.png' />
            </Grid>
          </>
        )}
        {(basement === "heated" || basement === "partially-heated") && (
          <>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={12}>
              <InsulationComponent fadein question='Sind die Kelleraußenwände gedämmt?' picture='House_sections_basement_walls_insulation.png' />
            </Grid>
          </>
        )}
        {(basement === "heated" || basement === "partially-heated") && (
          <>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={12}>
              <InsulationComponent fadein question='Ist die Kellerplatte gedämmt?' picture='House_sections_basement_floor_insulation.png' />
            </Grid>
          </>
        )}
        {(basement === "not-heated" || basement === "partially-heated") && (
          <>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={12}>
              <InsulationComponent fadein question='Ist die Kellerdecke gedämmt?' picture='House_sections_basement_ceiling_insulation.png' />
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Keller oder Boden' progressValue={41} content={content} helpContent={helpContent} />;
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Sind die Außenwände des Kellers
      <br />
      und der Kellerboden ungedämmt,
      <br />
      geht dort Wärme verloren.
      <br />
      Je nach Kellernutzung ist es
      <br />
      sinnvoll die Kellerdecke oder
      <br />
      die Außenbauteile des Kellers
      <br />
      zu dämmen.
      <br />
      <br />
      Sollten Sie keinen Keller haben,
      <br />
      benötigen wir Informationen zur
      <br />
      eventuellen Dämmung der Bodenplatte.
    </Typography>
  );
}
